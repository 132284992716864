import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";


const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}users/professionalList`} component={asyncComponent(() => import('./users/ProfessionalList'))}/>
      <Route path={`${match.url}users/customerList`} component={asyncComponent(() => import('./users/CustomerList'))}/>
      <Route path={`${match.url}report/pnl`} component={asyncComponent(() => import('./report/ProfitLoss'))}/>
      <Route path={`${match.url}config/tax`} component={asyncComponent(() => import('./config/Tax'))}/>
      <Route path={`${match.url}config/bonus`} component={asyncComponent(() => import('./config/Bonus'))}/>
    </Switch>
  </div>
);

export default App;
