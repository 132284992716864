import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
const jwtDecode = require('jwt-decode');

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    const decoded = jwtDecode(this.props.token);
    console.log({ decoded });
    return (<Auxiliary>

      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">


            <MenuItemGroup key="users-group" className="gx-menu-group" title={'Users'}>
              <Menu.Item key="users/professionalList">
                <Link to="/users/professionalList">
                  <Icon style={{ fontSize: 20 }} type="user" theme="outlined" />
                  <span>Professionals</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="users/customerList">
                <Link to="/users/customerList">
                  <Icon style={{ fontSize: 20 }} type="user" theme="outlined" />
                  <span>Customers</span>
                </Link>
              </Menu.Item>
            </MenuItemGroup>

            <MenuItemGroup key="users-group" className="gx-menu-group" title={'Reports'}>
              <Menu.Item key="report/pnl">
                <Link to="/report/pnl">
                  <Icon style={{ fontSize: 20 }} type="line-chart" theme="outlined" />
                  <span>P&L Report</span>
                </Link>
              </Menu.Item>
            </MenuItemGroup>

            <MenuItemGroup key="files-group" className="gx-menu-group" title={'Configuration'}>
              <Menu.Item key="config/tax">
                <Link to="/config/tax">
                  <Icon style={{ fontSize: 20 }} type="safety-certificate" theme="outlined" />
                  <span>Taxes</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="config/bonus">
                <Link to="/config/bonus">
                  <Icon style={{ fontSize: 20 }} type="safety-certificate" theme="outlined" />
                  <span>Sign-up Bonus</span>
                </Link>
              </Menu.Item>
            </MenuItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { authUser, token } = auth;
  const { navStyle, themeType, locale, pathname } = settings;
  return { navStyle, themeType, locale, pathname, authUser, token }
};
export default connect(mapStateToProps)(SidebarContent);

